/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import eventReducer from './eventSlice';
import settingReducer from './settingSlice';
import formReducer from './formSlice';
import orderReducer from './orderSlice';

export const store = configureStore({
  reducer: {
    event: eventReducer,
    setting: settingReducer,
    form: formReducer,
    order: orderReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
