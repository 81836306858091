import {
  Grid, Table, TableBody, TableRow, TableCell,
} from '@mui/material';
import React from 'react';
import { Event } from '../models/event';

export const Detail: React.FC<{ event: Event }> = (props) => {
  const { event } = props;
  return (
    <Grid container>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell padding="none" sx={{ fontSize: '1rem', width: '110px' }}>Kdy</TableCell>
            <TableCell sx={{ fontSize: '1rem' }}>{event.start}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell padding="none" sx={{ fontSize: '1rem' }}>Kde</TableCell>
            <TableCell sx={{ fontSize: '1rem' }}>{event.location}</TableCell>
          </TableRow>
          {event.url ? (
            <TableRow>
              <TableCell padding="none" sx={{ fontSize: '1rem' }}>Odkaz</TableCell>
              <TableCell sx={{ fontSize: '1rem' }}>
                <a style={{ wordBreak: 'break-all' }} target="_blank" href={event.url} rel="noreferrer">{event.urlDescription ?? event.url}</a>
              </TableCell>
            </TableRow>
          ) : null}
          {event.contact ? (
            <TableRow>
              <TableCell padding="none" sx={{ fontSize: '1rem' }}>Kontakt</TableCell>
              <TableCell sx={{ fontSize: '1rem' }}>
                {event.contact}
              </TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell sx={{ border: 0, padding: '10px 0 0 0', whiteSpace: 'pre-line' }} colSpan={2}>
              {event.description}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};
