import { createSlice, createAsyncThunk, Slice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Availability, Event } from '../models/event';
import { Reservation } from '../models/reservation';
import { API_BASE_URL } from '../setting/constants';

const namespace = 'event';

interface GetAvailabilityParams {
  eventId: string;
  categoryId: string;
  token: string | null;
}

export const getAvailability = createAsyncThunk(
  `${namespace}/getAvailability`,
  async ({ eventId, categoryId, token }: GetAvailabilityParams) => {
    const ava = await axios.get<Availability>(
      `${API_BASE_URL}/events/${eventId}/category/${categoryId}/availability`,
      token ? {headers: {'x-auth': token}} : undefined,
    );
    return ava.data;
  },
);

interface GetEventParams {
  id: string;
  token: string | null;
}

export const getEvent = createAsyncThunk(
  `${namespace}/get`,
  async ({ id, token }: GetEventParams) => {
    const event = (await axios.get<Event>(`${API_BASE_URL}/events/${id}`, token ? {headers: {'x-auth': token}} : undefined)).data;
    return event;
  },
);

interface SetReservationParams {
  token: string | null;
  eventId: string;
  categoryId: string;
}

export const setReservation = createAsyncThunk(
  `${namespace}/setReservation`,
  async ({ token, eventId, categoryId }: SetReservationParams, { rejectWithValue }) => {
    let reservResp: any;
    try {
      reservResp = await axios.post<Reservation>(`${API_BASE_URL}/events/${eventId}/category/${categoryId}/reserve`, {}, token ? {headers: {'x-auth': token}} : undefined);
    } catch (e: any) {
      return rejectWithValue({ error: true, message: e.response?.data?.message ?? null } as ReserveError);
    }
    return reservResp?.data;
  },
);

export const setUnreserve = createAsyncThunk(
  `${namespace}/setUnreserve`,
  async ({ token, eventId, categoryId }: SetReservationParams) => (
    await axios.post<Reservation>(`${API_BASE_URL}/events/${eventId}/category/${categoryId}/unreserve`, {}, token ? {headers: {'x-auth': token}} : undefined)
  ).data,
);

interface SetPrivateCodeParams {
  token: string | null;
  eventId: string;
  code: string;
}

export const setPrivateCode = createAsyncThunk(
  `${namespace}/setPrivateCode`,
  async ({ token, eventId, code }: SetPrivateCodeParams, { rejectWithValue }) => {
    let reservResp: any;
    try {
      reservResp = await axios.post<{ token: string }>(`${API_BASE_URL}/events/${eventId}/code`, { code }, token ? {headers: {'x-auth': token}} : undefined);
    } catch (e: any) {
      return rejectWithValue({ error: true, message: e.response?.data?.message ?? null } as ReserveError);
    }
    return reservResp?.data;
  },
);

export interface ReserveError {
  error: boolean;
  message: string | null;
}

interface EventState {
  event: Event | undefined;
  availability: {
    availability: number;
    category: string;
    event: string;
  }[];
  eventLoading: boolean;
  eventError: boolean;
  reservationPending: boolean;
  reservationError: ReserveError;
}

const eventSlice: Slice<EventState> = createSlice({
  name: namespace,
  initialState: {
    event: undefined,
    eventLoading: false,
    eventError: false,
    reservationPending: false,
    availability: [],
    reservationError: {
      error: false,
      message: null,
    },
  } as EventState,
  reducers: {
    clearReservError: (state) => {
      state.reservationError = { error: false, message: null };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvent.pending, (state) => {
      state.eventLoading = true;
      state.eventError = false;
    });
    builder.addCase(getEvent.fulfilled, (state, action) => {
      state.eventLoading = false;
      state.eventError = false;
      state.event = action.payload;
    });
    builder.addCase(getEvent.rejected, (state) => {
      state.eventLoading = false;
      state.eventError = true;
    });
    builder.addCase(setReservation.pending, (state) => {
      state.reservationError = { error: false, message: null };
      state.reservationPending = true;
    });
    builder.addCase(setReservation.fulfilled, (state) => {
      state.reservationPending = false;
    });
    builder.addCase(setReservation.rejected, (state, action) => {
      state.reservationPending = false;
      state.reservationError = action.payload as ReserveError;
    });
    builder.addCase(getAvailability.fulfilled, (state, {payload}) => {
      const index = state.availability.findIndex((ava) => ava.category === payload.category && ava.event === payload.event);
      if (index > -1) {
        state.availability[index] = payload;
      } else {
        state.availability.push(payload);
      }
    });
  },
});

export default eventSlice.reducer;
export const { clearReservError } = eventSlice.actions;
