import {
  Grid, TextField, Button, Checkbox, FormControlLabel, Typography, FormHelperText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../redux/store';
import { Event } from '../models/event';
import { setTerms, resetForm } from '../redux/formSlice';
import { clearOrderError, createOrder } from '../redux/orderSlice';
import { clearReservError, getEvent } from '../redux/eventSlice';

export interface TermsProps {
  setShopStep: (step: number) => void;
  event: Event;
}

interface TermsErrors {
  terms?: string;
  news?: string;
}

export const Terms: React.FC<TermsProps> = (props) => {
  const { setShopStep, event } = props;

  const dispatch = useTypedDispatch();
  const formValues = useTypedSelector((state) => state.form);
  const token = useTypedSelector((state) => state.setting.token);
  const [formErrors, setFormErrors] = useState<null | TermsErrors>({});
  const [submitted, setSubmitted] = useState(false);

  const validate = (): null | TermsErrors => {
    const errors: TermsErrors = {};
    let error = false;
    if (!formValues.terms.terms) {
      errors.terms = 'Musíte souhlasit s obchodními podmínkami a zpracováním údajů!';
      error = true;
    }
    setFormErrors(error ? errors : null);
    return error ? errors : null;
  };

  useEffect(() => {
    validate();
  }, [formValues.terms]);

  const category = event.categories.find((cat) => cat.reserved);

  const confirmOrder = async () => {
    try {
      setSubmitted(true);
      const errors = validate();
      if (!errors) {
        setFormErrors(null);
        const coResponse = await dispatch(createOrder({
          token,
          req: {
            clientSecret: '4CW5jv5Io2wCw9xDyKwAROIjciCvcpo1',
            currency: 'CZK',
            language: 'cs',
            email: formValues.info.email,
            firstname: formValues.info.name,
            surname: formValues.info.surname,
            memberType: category?.memberType,
            memberCode: formValues.info.code,
            event: event._id,
            news: formValues.terms.news,
            terms: formValues.terms.terms,
            lines: event.categories.filter((cat) => cat.reserved).map((cat) => ({
              category: cat._id,
              quantity: 1,
            })),
          },
        }));
        const { payload }: { payload: any } = coResponse;
        if (!payload?.error) {
          dispatch(getEvent({ id: event._id, token }));
          dispatch(resetForm(null));
          dispatch(clearReservError(null));
          dispatch(clearOrderError(null));
          setShopStep(3);
        } else {
          dispatch(getEvent({ id: event._id, token }));
        }
      }
    } catch (e) {
      dispatch(getEvent({ id: event._id, token }));
    }
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: '20px', padding: '0 10px' }}>
      <Grid item xs={12}>
        <Typography variant="h5">{`Vstupenka: ${category?.name} - ${category?.priceCZK} Kč`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Obchodní podmínky"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ marginTop: '5px' }}
          onChange={() => {}}
          spellCheck={false}
          multiline
          minRows={5}
          maxRows={10}
          value={event.terms ?? ''}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '10px !important' }}>
        <FormControlLabel
          control={(
            <Checkbox
              name="terms"
              value={formValues.terms}
              onChange={(changeEvent) => dispatch(setTerms({ ...formValues.terms, terms: changeEvent.target.checked }))}
            />
          )}
          label="Souhlasím s obchodními podmínkami a zpracováním osobních údajů *"
        />
        {submitted && formErrors?.terms ? (
          <FormHelperText error style={{ marginTop: '0' }}><span>{formErrors?.terms}</span></FormHelperText>
        ) : null}
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
        <FormControlLabel
          control={(
            <Checkbox
              value={formValues.terms.terms}
              onChange={(changeEvent) => dispatch(setTerms({ ...formValues.terms, news: changeEvent.target.checked }))}
              name="terms"
            />
          )}
          label="Souhlasím se zasíláním novinek od organizátora akce"
          value={formValues.terms.news}
        />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'right' }}>
        <Button variant="outlined" onClick={() => confirmOrder()}>Objednat</Button>
      </Grid>
    </Grid>
  );
};
