import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

const namespace = 'form';

export interface InfoState {
  name: string;
  surname: string;
  email: string;
  code?: string;
}

export interface TermsState {
  terms: boolean;
  news: boolean;
}

interface FormState {
  info: InfoState;
  terms: TermsState;
}

const initialState = {
  info: {
    email: '',
    name: '',
    surname: '',
  },
  terms: {
    news: false,
    terms: false,
  },
} as FormState;

const formSlice: Slice<FormState> = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setForm: (state, action: PayloadAction<InfoState>) => {
      state.info = action.payload;
    },
    setTerms: (state, action: PayloadAction<TermsState>) => {
      state.terms = action.payload;
    },
    resetForm: (state) => {
      state.info = initialState.info;
      state.terms = initialState.terms;
    },
  },
});

export default formSlice.reducer;
export const { setForm, setTerms, resetForm } = formSlice.actions;
