import {
  Grid, TextField, Button, Typography, Alert,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Event } from '../models/event';
import { setForm } from '../redux/formSlice';
import { useTypedDispatch, useTypedSelector } from '../redux/store';
import { API_BASE_URL } from '../setting/constants';

interface InfoErrors {
  name?: string;
  surname?: string;
  email?: string;
  code?: string;
}

export interface InfoProps {
  event: Event;
  setShopStep: (step: number) => void;
}

export const Info: React.FC<InfoProps> = (props) => {
  const { setShopStep, event } = props;
  const formValues = useTypedSelector((state) => state.form.info);
  const dispatch = useTypedDispatch();
  const [formErrors, setFormErrors] = useState<null | InfoErrors>({});
  const [submitted, setSubmitted] = useState(false);

  const category = event.categories.find((cat) => cat.reserved);

  const validate = (): null | InfoErrors => {
    const errors: InfoErrors = {};
    let error = false;
    if (!formValues.name) {
      errors.name = 'Jméno musí být vyplněno!';
      error = true;
    }
    if (!formValues.surname) {
      errors.surname = 'Příjmení musí být vyplněno!';
      error = true;
    }
    if (!formValues.email) {
      errors.email = 'Email musí být vyplněn!';
      error = true;
    } else if (!(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/).test(formValues.email)) {
      errors.email = 'Nesprávný formát emailu!';
      error = true;
    }
    if (!formValues.code && category?.memberType) {
      errors.code = `Pole ${category.memberDescription} musí být vyplněno!`;
      error = true;
    }
    setFormErrors(error ? errors : null);
    return error ? errors : null;
  };

  useEffect(() => {
    validate();
  }, [formValues]);

  const continueShop = async () => {
    let errors = validate();
    if (errors) {
      setSubmitted(true);
    } else if (formValues.code && category?.memberType) {
      const checkResponse = await axios.post<{check: boolean }>(
        `${API_BASE_URL}/events/${event._id}/category/${category._id}/memberCheck`,
        { email: formValues.email, code: formValues.code},
      );
      if (!checkResponse?.data?.check) {
        errors = { code: `Pole ${category.memberDescription} nesouhlasí!`};
        setSubmitted(true);
        setFormErrors(errors);
      } else {
        setFormErrors(null);
        setShopStep(2);
      }
    } else {
      setFormErrors(null);
      setShopStep(2);
    }
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: '20px', padding: '0 10px' }}>
      <Grid item sm={12}>
        <Typography variant="h5">{`Vstupenka: ${category?.name} - ${category?.priceCZK} Kč`}</Typography>
      </Grid>
      {category?.memberHint ? (
        <Grid item sm={12}>
          <Alert
            variant="filled"
            severity="info"
          >
            {category?.memberHint}
          </Alert>
        </Grid>
      ) : null}
      <Grid item sm={6}>
        <TextField
          type="text"
          label="Jméno"
          name="firstname"
          variant="outlined"
          fullWidth
          size="small"
          value={formValues.name}
          onChange={(changeEvent) => dispatch(setForm({ ...formValues, name: changeEvent.target.value }))}
          helperText={submitted && formErrors?.name}
          error={submitted && !!formErrors && !!formErrors.name}
        />
      </Grid>
      <Grid item sm={6}>
        <TextField
          type="text"
          label="Příjmení"
          name="surname"
          variant="outlined"
          fullWidth
          size="small"
          value={formValues.surname}
          onChange={(changeEvent) => dispatch(setForm({ ...formValues, surname: changeEvent.target.value }))}
          helperText={submitted && formErrors?.surname}
          error={submitted && !!formErrors && !!formErrors.surname}
        />
      </Grid>
      <Grid item sm={6}>
        <TextField
          type="email"
          label="Email"
          name="email"
          variant="outlined"
          fullWidth
          size="small"
          value={formValues.email}
          onChange={(changeEvent) => dispatch(setForm({ ...formValues, email: changeEvent.target.value }))}
          helperText={submitted && formErrors?.email}
          error={submitted && !!formErrors && !!formErrors.email}
        />
      </Grid>
      {category?.memberType ? (
        <Grid item sm={6}>
          <TextField
            type="text"
            name="memberCode"
            label={category?.memberDescription ?? ''}
            variant="outlined"
            fullWidth
            size="small"
            value={formValues.code}
            onChange={(changeEvent) => dispatch(setForm({ ...formValues, code: changeEvent.target.value }))}
            helperText={submitted && formErrors?.code}
            error={submitted && !!formErrors && !!formErrors.code}
          />
        </Grid>
      ) : null}
      <Grid item sm={12} sx={{ textAlign: 'right' }}>
        <Button variant="outlined" onClick={() => continueShop()}>Pokračovat</Button>
      </Grid>
    </Grid>
  );
};
