import { createAsyncThunk, createSlice, Slice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Order } from '../models/order';
import { API_BASE_URL } from '../setting/constants';

const namespace = 'order';

export interface CreateOrderParams {
  clientSecret?: string;
  event: string;
  firstname: string;
  surname: string;
  email: string;
  memberCode?: string;
  memberType?: string;
  terms: boolean;
  news: boolean;
  currency: string;
  language: string;
  lines: {
    category: string;
    quantity: number;
  }[];
}

export interface CreateOrderResp {
  order?: string;
}

export const createOrder = createAsyncThunk(
  `${namespace}/get`,
  async ({ token, req }: { token?: string | null, req: CreateOrderParams }, { rejectWithValue }) => {
    let orderResp: any;
    try {
      orderResp = (await axios.post<CreateOrderResp>(`${API_BASE_URL}/orders`, req, token ? {headers: {'x-auth': token}} : undefined)).data;
    } catch (e: any) {
      return rejectWithValue({ error: true, message: e.response?.data?.message ?? null } as OrderError);
    }
    const order = (await axios.get<Order>(`${API_BASE_URL}/orders/${orderResp.order}`, token ? {headers: {'x-auth': token}} : undefined)).data;
    return order;
  },
);

export interface OrderError {
  error?: boolean;
  message?: string | null;
}
export interface OrderState {
  order: Order;
  error: OrderError;
}

const orderSlice: Slice<OrderState> = createSlice({
  name: namespace,
  initialState: {
  } as OrderState,
  reducers: {
    clearOrderError: (state) => {
      state.error = { error: false, message: null };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.order = action.payload;
    });
    builder.addCase(createOrder.rejected, (state, action) => {
      state.error = action.payload as OrderError;
    });
  },
});

export default orderSlice.reducer;
export const { clearOrderError } = orderSlice.actions;
