import {
  Alert, Button, ButtonProps, Step, StepButton, Stepper, styled,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Event } from '../models/event';
import {
  clearReservError, getAvailability, getEvent, setUnreserve,
} from '../redux/eventSlice';
import { clearOrderError } from '../redux/orderSlice';
import { useTypedDispatch, useTypedSelector } from '../redux/store';
import { Complete } from './Complete';
import { Info } from './Info';
import { Terms } from './Terms';
import { Tickets } from './Tickets';

const ColorButton = styled(Button)<ButtonProps>(() => ({
  color: '#fff',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: '#fff',
  },
}));

export const Shop: React.FC<{ event: Event }> = (props) => {
  const { event } = props;
  const dispatch = useTypedDispatch();

  const token = useTypedSelector((state) => state.setting.token);
  const orderError = useTypedSelector((state) => state.order.error);
  const reservError = useTypedSelector((state) => state.event.reservationError);

  const reservedUntil = event.categories.find((cat) => cat.reserved === 1)?.reservedUntil;
  const [shopStep, setShopStep] = useState(0);

  const [timer, setTimer] = useState<null | NodeJS.Timeout>(null);
  const [expiresIn, setExpiresIn] = useState<null | number>();
  const [expired, setExpired] = useState<boolean>(false);

  useEffect(() => {
    if (reservedUntil) {
      setExpired(false);
      setTimer(setInterval(async () => {
        setExpiresIn(moment(reservedUntil).diff(moment()));
        if (moment(reservedUntil).diff(moment()) < 0) {
          setExpired(true);
          const eventResult = (await (dispatch(getEvent({ id: event._id, token }))))?.payload as Event;
          eventResult.categories.forEach((cat) => {
            dispatch(getAvailability({ token, categoryId: cat._id, eventId: eventResult._id }));
          });
          setShopStep(0);
        }
      }, 500));
    } else {
      if (timer) {
        clearInterval(timer);
        setTimer(null);
      }
      setExpiresIn(null);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
        setTimer(null);
      }
    };
  }, [event.categories.find((cat) => cat.reserved)?.reservedUntil]);

  const unreserve = async () => {
    if (window.confirm('Opravdu chcete zrušit rezervaci?') === true) {
      const reservedCat = event.categories.find((cat) => cat.reserved === 1);
      if (reservedCat) {
        await dispatch(setUnreserve({ categoryId: reservedCat._id, eventId: event._id, token }));
        const eventResult = (await (dispatch(getEvent({ id: event._id, token }))))?.payload as Event;
        eventResult.categories.forEach((cat) => {
          dispatch(getAvailability({ token, categoryId: cat._id, eventId: eventResult._id }));
        });
        setShopStep(0);
      }
    }
  };

  const category = event.categories.find((cat) => cat.reserved);

  return (
    <>
      <Stepper activeStep={shopStep}>
        <Step key="tickets" onClick={() => setShopStep(0)}>
          <StepButton>Vstupenky</StepButton>
        </Step>
        <Step key="informations" onClick={shopStep > 1 ? () => setShopStep(1) : () => {}} disabled={shopStep < 1}>
          <StepButton>Údaje</StepButton>
        </Step>
        <Step key="terms" onClick={shopStep > 2 ? () => setShopStep(2) : () => {}} disabled={shopStep < 2}>
          <StepButton>Podmínky</StepButton>
        </Step>
        <Step key="summary" disabled={shopStep < 3}>
          <StepButton>Souhrn</StepButton>
        </Step>
      </Stepper>

      {shopStep === 0 ? (
        <Tickets event={event} setShopStep={setShopStep} />
      ) : null}

      {shopStep === 1 ? (
        <Info event={event} setShopStep={setShopStep} />
      ) : null}

      {shopStep === 2 ? (
        <Terms event={event} setShopStep={setShopStep} />
      ) : null}

      {shopStep === 3 ? (
        <Complete event={event} setShopStep={setShopStep} />
      ) : null}

      {expiresIn && expiresIn > 0 ? (
        <Alert
          variant="filled"
          severity={expiresIn > 20000 ? 'success' : 'warning'}
          action={(
            <ColorButton size="small" variant="outlined" onClick={() => unreserve()}>Zrušit</ColorButton>
        )}
          sx={{ marginTop: '20px' }}
        >
          {`Rezervováno ještě ${moment.utc(expiresIn).format('mm:ss')}`}

        </Alert>
      ) : null}

      {expired ? (
        <Alert
          variant="filled"
          severity="error"
          action={(
            <ColorButton size="small" variant="outlined" onClick={() => setExpired(false)}>Zavřít</ColorButton>
        )}
          sx={{ marginTop: '20px' }}
        >
          Rezervace vypršela.

        </Alert>
      ) : null}

      {orderError?.error ? (
        <Alert
          variant="filled"
          severity="error"
          action={(
            <ColorButton size="small" variant="outlined" onClick={() => dispatch(clearOrderError(null))}>Zavřít</ColorButton>
              )}
          sx={{ marginTop: '20px' }}
        >
          {orderError?.message === 'reservation-expired' ? 'Rezervace vypršela.' : null}
          {orderError?.message === 'member-error' ? `${category?.memberDescription ?? 'Členský kód'} nesouhlasí nebo je již použit u jiné objednávky!` : null}
          {orderError?.message === 'terms-not-accepted' ? 'Obchodní podmínky musí být přijaty!' : null}
          {orderError?.message === null ? 'Chyba!' : null}
        </Alert>
      ) : null}

      {reservError.error ? (
        <Alert
          variant="filled"
          severity="error"
          action={(
            <ColorButton size="small" variant="outlined" onClick={() => dispatch(clearReservError(null))}>Zavřít</ColorButton>
                    )}
          sx={{ marginTop: '20px' }}
        >
          {reservError?.message === 'sold-out' ? 'Kategorie je v tuto chvíli vyprodaná.' : null}
        </Alert>
      ) : null}
    </>
  );
};
