import { createSlice, Slice } from '@reduxjs/toolkit';
import { setPrivateCode, setReservation } from './eventSlice';

const namespace = 'event';

interface SettingState {
  token: string | null;
}

const settingSlice: Slice<SettingState> = createSlice({
  name: namespace,
  initialState: {
    token: null,
  } as SettingState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setReservation.fulfilled, (state, action) => {
      state.token = action.payload.token;
    });
    builder.addCase(setPrivateCode.fulfilled, (state, action) => {
      state.token = action.payload.token;
    });
  },
});

export default settingSlice.reducer;
