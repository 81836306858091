import {
  Table, TableHead, TableRow, TableCell, TableBody, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Category } from '../models/category';
import { Event } from '../models/event';
import { Reservation } from '../models/reservation';
import {
  getAvailability, getEvent, setPrivateCode, setReservation,
} from '../redux/eventSlice';
import { clearOrderError } from '../redux/orderSlice';
import { useTypedDispatch, useTypedSelector } from '../redux/store';

export interface TicketsProps {
  setShopStep: (step: number) => void;
  event: Event;
}

export const Tickets: React.FC<TicketsProps> = (props) => {
  const { setShopStep, event } = props;
  const dispatch = useTypedDispatch();

  const token = useTypedSelector((state) => state.setting.token);
  const availability = useTypedSelector((state) => state.event.availability);

  const reserve = async (eventId: string, categoryId: string) => {
    const response = (await dispatch(setReservation({ token, categoryId, eventId }))).payload as Reservation;
    if (response?.token) {
      const eventResult = (await (dispatch(getEvent({ id: event._id, token: response?.token }))))?.payload as Event;
      eventResult.categories.forEach((cat) => {
        dispatch(getAvailability({ token: response?.token, categoryId: cat._id, eventId: eventResult._id }));
      });
      dispatch(clearOrderError(null));
      setShopStep(1);
    } else {
      const eventResult = (await (dispatch(getEvent({ id: event._id, token: null }))))?.payload as Event;
      eventResult.categories.forEach((cat) => {
        dispatch(getAvailability({ categoryId: cat._id, eventId: eventResult._id, token: null }));
      });
    }
  };

  const generateTicketButton = (cat: Category, reservedCatId: null | string): JSX.Element | null => {
    const { reserved, _id: catId } = cat;
    if (!(moment(cat.start).isBefore())) {
      return <Button disabled variant="outlined" onClick={() => {}}>Zatím nedostupné</Button>;
    }
    if (reserved) {
      return <Button variant="outlined" onClick={() => setShopStep(1)}>Pokračovat</Button>;
    }
    if ((availability?.find((ava) => ava.category === catId)?.availability ?? 0) > 0) {
      return <Button disabled={!!reservedCatId && (reservedCatId !== catId)} variant="outlined" onClick={() => reserve(event._id, catId)}>Vybrat</Button>;
    }
    return <Button disabled variant="outlined" onClick={() => {}}>Nedostupné</Button>;
  };

  const [privateOpen, setPrivateOpen] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);

  const confirmCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCodeError(false);
    const resp = await dispatch(setPrivateCode({ token, eventId: event._id, code }));
    if (!resp.payload.error) {
      const eventResult = (await (dispatch(getEvent({ id: event._id, token: resp.payload.token }))))?.payload as Event;
      eventResult.categories.forEach((cat) => {
        dispatch(getAvailability({ categoryId: cat._id, eventId: eventResult._id, token: resp.payload.token }));
      });
      setPrivateOpen(false);
    } else {
      setCodeError(true);
    }
  };

  return (
    <div>
      {event.categories.filter((cat) => cat.type === 'public' || cat.type === 'coded').length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Název</TableCell>
              <TableCell>Od</TableCell>
              <TableCell>Do</TableCell>
              <TableCell>Cena</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {event.categories.filter((cat) => cat.type === 'public' || cat.type === 'coded').map((cat) => (
              <TableRow key={cat._id}>
                <TableCell>{cat.name}</TableCell>
                <TableCell>
                  {moment(cat.start).format('DD.MM.YYYY HH:mm')}
                </TableCell>
                <TableCell>
                  {moment(cat.end).format('DD.MM.YYYY HH:mm')}
                </TableCell>
                <TableCell>
                  {cat.priceCZK}
                  {' '}
                  Kč
                </TableCell>
                <TableCell align="right">
                  {generateTicketButton(cat, event.categories.find((cat1) => cat1.reserved)?._id ?? null)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div style={{ textAlign: 'center', paddingTop: '30px' }}>Žádné vstupenky k dispozici</div>
      )}
      <div style={{ textAlign: 'right', paddingTop: '10px' }}>
        <Button variant="text" startIcon={<LockOpenIcon />} style={{ color: '#b4b4b4' }} onClick={() => setPrivateOpen(true)}>
          Soukromé kategorie
        </Button>
      </div>
      <Dialog open={privateOpen} onClose={() => setPrivateOpen(false)}>
        <form onSubmit={confirmCode}>
          <DialogTitle>Zadejte kód soukromé kategorie</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              id="code"
              name="code"
              label="Kód soukromé kategorie"
              type="text"
              fullWidth
              variant="standard"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              error={codeError}
              helperText={codeError ? 'Neplatný kód' : undefined}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPrivateOpen(false)}>Storno</Button>
            <Button type="submit">Potvrdit</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
