import {
  Grid, Button, Typography, Alert,
} from '@mui/material';
import QRCode from 'qrcode';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Event } from '../models/event';
import { useTypedSelector } from '../redux/store';

export interface CompleteProps {
  setShopStep: (step: number) => void;
  event: Event;
}

export const Complete: React.FC<CompleteProps> = (props) => {
  const { event, setShopStep } = props;
  const order = useTypedSelector((state) => state.order.order);

  const toTickets = () => {
    setShopStep(0);
  };

  const [qrImage, setQrImage] = useState<null | string>(null);

  const createQrImage = async (qrCode: string) => {
    setQrImage(await QRCode.toDataURL(qrCode));
  };

  useEffect(() => {
    if (order.paymentQR) {
      createQrImage(order.paymentQR);
    } else {
      setQrImage(null);
    }
  }, [order.paymentQR]);

  return (
    <Grid container sx={{ marginTop: '20px', padding: '0 10px' }}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h5">{`Objednávka ${order.orderNo} odeslána`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} sx={{ marginTop: '10px' }}>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <Typography
            component="li"
            variant="subtitle1"
          >
            <strong><u>Osobní údaje</u></strong>
          </Typography>
          <Typography
            component="li"
            variant="subtitle1"
          >
            <strong>Jméno: </strong>
            {`${order.firstname} ${order.surname}`}
          </Typography>
          <Typography
            component="li"
            variant="subtitle1"
          >
            <strong>Email: </strong>
            {order.email}
          </Typography>
        </ul>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ marginTop: '10px' }}>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <Typography
            component="li"
            variant="subtitle1"
          >
            <strong><u>Platební údaje</u></strong>
          </Typography>
          <Typography
            component="li"
            variant="subtitle1"
          >
            <strong>Číslo účtu: </strong>
            {`${event.accountNo}/${event.bankNo}`}
          </Typography>
          {order.constantSymbol ? (
            <Typography
              component="li"
              variant="subtitle1"
            >
              <strong>Konstantní symbol: </strong>
              {order.constantSymbol ?? ''}
            </Typography>
          ) : null}
          <Typography
            component="li"
            variant="subtitle1"
          >
            <strong>Variabilní symbol: </strong>
            {order.variableSymbol}
          </Typography>
          {!event.nonZS ? (
            <Typography
              component="li"
              variant="subtitle1"
            >
              <strong>Zpráva pro příjemce: </strong>
              Členský příspěvek
              {' '}
              <span style={{ fontStyle: 'italic' }}>JMÉNO PŘÍJMENÍ</span>
            </Typography>
          ) : null}
          <Typography
            component="li"
            variant="subtitle1"
          >
            <strong>Částka: </strong>
            {`${order.lines.reduce((acc, curr) => acc + (curr.price * curr.quantity), 0)} ${order.currency}`}
          </Typography>
          <Typography
            component="li"
            variant="subtitle1"
          >
            <strong>Datum splatnosti: </strong>
            {moment(order.dueDate).format('DD.MM.YYYY')}
          </Typography>
        </ul>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ marginTop: '10px' }}>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <Typography
            component="li"
            variant="subtitle1"
          >
            <strong><u>Platební QR kód</u></strong>
          </Typography>
        </ul>
        {qrImage ? (
          <img src={qrImage} alt="Platební QR" style={{ border: '1px solid rgba(0,0,0,0.3)' }} />
        ) : null}
      </Grid>
      <Grid item xs={12} sm={6} sx={{ marginTop: '10px' }}>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <Typography
            component="li"
            variant="subtitle1"
          >
            <strong><u>Zahraniční platba</u></strong>
          </Typography>
          <Typography
            component="li"
            variant="subtitle1"
          >
            <strong>IBAN: </strong>
            {event.iban}
          </Typography>
          <Typography
            component="li"
            variant="subtitle1"
          >
            <strong>SWIFT: </strong>
            {event.swift}
          </Typography>
          {event.bankName ? (
            <Typography
              component="li"
              variant="subtitle1"
            >
              <strong>Název banky: </strong>
              {event.bankName}
            </Typography>
          ) : null}
          {event.bankAddress ? (
            <Typography
              component="li"
              variant="subtitle1"
            >
              <strong>Adresa banky: </strong>
              {event.bankAddress}
            </Typography>
          ) : null}
          {event.companyName ? (
            <Typography
              component="li"
              variant="subtitle1"
            >
              <strong>Vlastník účtu: </strong>
              {event.bankAddress}
            </Typography>
          ) : null}
        </ul>
      </Grid>
      <Grid item xs={12}>
        <Alert
          variant="filled"
          severity="info"
          sx={{ marginTop: '20px' }}
        >
          Email s platebními údaji a vstupenkami může být některými službami zařazen do adresáře hromadné pošty, nebo spamu!
        </Alert>
      </Grid>
      <Grid item sm={12} sx={{ textAlign: 'right', marginTop: '20px' }}>
        <Button variant="outlined" onClick={toTickets}>Zpět</Button>
      </Grid>
    </Grid>
  );
};
