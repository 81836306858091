import React, { useEffect } from 'react';
import {
  CircularProgress,
  Container, CssBaseline, Grid, Paper, Typography, Alert,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Detail } from './Detail';
import { Shop } from './Shop';
import { getAvailability, getEvent } from '../redux/eventSlice';
import { useTypedDispatch, useTypedSelector } from '../redux/store';
import { Event } from '../models/event';

export const EventView: React.FC = () => {
  const { eventId } = useParams();
  const dispatch = useTypedDispatch();

  const token = useTypedSelector((state) => state.setting.token);
  const event = useTypedSelector((state) => state.event.event);
  const eventError = useTypedSelector((state) => state.event.eventError);
  const availability = useTypedSelector((state) => state.event.availability);

  const getEventAsync = async () => {
    try {
      if (eventId) {
        const eventResult = (await (dispatch(getEvent({ id: eventId, token }))))?.payload as Event;
        eventResult.categories.forEach((cat) => {
          dispatch(getAvailability({ token, categoryId: cat._id, eventId: eventResult._id }));
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getEventAsync();
  }, [eventId]);

  if (eventError) return (<Alert severity="error">Událost neexistuje.</Alert>);

  if (!event) return (<CircularProgress />);

  return (
    <>
      <CssBaseline />
      <Container maxWidth="xl" component="main" sx={{ backgroundColor: 'grey.100', paddingBottom: '40px', paddingTop: '60px' }}>
        {event.banner ? (
          <Paper
            sx={{
              height: 180,
              position: 'relative',
              backgroundColor: 'grey.800',
              color: '#fff',
              mb: 4,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: `url(${event.banner})`,
            }}
          />
        ) : null}
        <Grid container spacing={4}>
          <Grid item md={12}>
            <Typography variant="h3" component="div">
              {event.name}
            </Typography>
          </Grid>
          <Grid item md={5} sm={12}>
            <Detail event={event} />
          </Grid>
          <Grid item md={7} sm={12}>
            <Shop event={event} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
